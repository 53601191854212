<template>
  <div style="margin: 20px" v-loading="loading">
    <el-button type="primary" @click="$router.back()" style="margin-bottom: 20px">Вернуться к списку показателей</el-button>
    <el-row>
      <el-col :span="12">
        <label>{{`Полнота предоставления данных мониторинга ${completeness.completed || 0}/${completeness.should_be_entered || 0}`}}</label>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <label>Период закроется {{ getPeriodEnd() }}</label>
      </el-col>
    </el-row>
    <el-progress :percentage="calcPercent(completeness.completed, completeness.should_be_entered)" color="#67c23a"></el-progress>
    <br>
    <h3 style="color: #666;">{{pki.name}}</h3>
    <simple-table
      v-if="value_rules.length > 0"
      :list="value_rules"
      name="symbols"
      :show-pagination="false"
      :columns="symbolsColumns"/>
    <br>
<!--    <div style="color: grey;" v-if="pki.target_reachable !== null">-->
<!--      <b>Направленность показателя:</b>-->
<!--      <p>-->
<!--        {{ pki.target_reachable ? 'Повышаемый' : 'Понижаемый' }}-->
<!--      </p>-->
<!--      <b>Присвоение балла по уровню достижения целевого значения:</b>-->
<!--      <p>-->
<!--        {{ pki.rating_by_target ? 'Да' : 'Нет' }}-->
<!--      </p>-->
<!--    </div>-->
<!--    <div style="color: grey;" v-if="pki.rating_by_target">-->
<!--      <b>Целевое значение:</b>-->
<!--      <p>-->
<!--        {{ pki.target_value }}-->
<!--      </p>-->
<!--    </div>-->
    <el-alert v-if="!file_exist.pdf" type="error" center>Внимание! Не приложен файл отчета в формате PDF. Необходимо приложить файл до завершения срока ввода данных!</el-alert>
    <storage-overall-table
      :list="tableData"
      :total="total"
      name="storage"
      :columns="columns"
      :value_rules="value_rules"
      :rule_io="rule_io"
      :rule="pki.rule"
      :pki="pki"
      :need-id="false"
      :need-actions="true"
      @storage-edit="showModalEdit"
      @storage-view="showModalView"
      @fetch-data="fetchData"
      @change-data-exist="changeDataExist"
      @change-data-exist-not-result="changeDataExistNotResult"
      @change-target-value="changeTargetValue"
      @change-graph="getStorageCompletness"
    />
    <el-dialog top="5vh" :visible.sync="dialogVisibleEdit">
      <storage-detail v-if="dialogVisibleEdit" :id="storageId" @updated="fetchData"/>
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="dialogVisibleView">
      <storage-view-modal v-if="dialogVisibleView" :storage-id="storageId"/>
    </el-dialog>
  </div>
</template>

<script>
import StorageOverallTable from '@/components/StorageOverallTable'
import StorageDetail from './StorageDetail'
import StorageViewModal from '@/views/storage/StorageViewModal'
import {
  getstorageCompletness,
  getStorageIogvList, getstorageKpCompletness, getStorageKpIogvList, getStorageKpOverallList,
  getStorageOverallList,
  saveDataExistMultiple,
  saveDataExistMultipleNotResult
} from '@/api/api'
import SimpleTable from '@/components/SimpleTable'
export default {
  components: {
    StorageOverallTable,
    StorageDetail,
    SimpleTable,
    StorageViewModal
  },
  data () {
    return {
      isKP: false,
      pki_id: null,
      periodEnd: null,
      pki: {
        name: '',
        rule: 0
      },
      file_exist: {
        pdf: true
      },
      completeness: {
        completed: null,
        percent: null,
        should_be_entered: null
      },
      period_id: null,
      value_rules: [],
      rule_io: [],
      iogvs: [],
      tableData: [],
      params: undefined,
      total: 0,
      loading: false,
      dialogVisibleEdit: false,
      dialogVisibleView: false,
      storageId: null,
      actions: [
        {
          name: 'edit'
        },
        {
          name: 'view'
        }
      ],
      symbolsColumns: [
        {
          field: 'description',
          label: 'Параметры',
          align: 'left'
        },
        {
          field: 'asciiMathDesignatio',
          label: 'Обозначение',
          type: 'formula',
          width: '130px',
          align: 'left'
        },
        {
          field: 'unitOfMeasurement.short_name',
          label: 'В чем измеряется',
          width: '160px',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    columns () {
      const iogvs = this.iogvs
      return [
        {
          field: 'pki_index',
          label: 'Индекс КПР',
          filterable: false,
          width: '100px'
        },
        {
          field: 'ebosp_id',
          label: 'ИОГВ',
          width: '150px',
          items: iogvs,
          filter (value) {
            return iogvs.find(x => x.id === value)?.title
          },
          multiple: true
        },
        {
          field: 'formula',
          label: 'Расчёт',
          type: 'formula',
          filterable: false,
          width: '150px'
        },
        {
          field: 'value_rule',
          label: 'Параметры',
          type: 'value_rule'
        },
        {
          field: 'result',
          label: 'Результат',
          items: [
            {
              title: 'Предоставлен',
              id: 'exist'
            },
            {
              title: 'Не предоставлен',
              id: 'not_exist'
            }
          ],
          filter (value, row) {
            return (value && value !== '-') ? `${value} ${row.unit_of_measurement ?? ''}` : value
          },
          align: 'center',
          width: '100px'
        },
        {
          field: 'rating',
          label: 'Балл',
          align: 'center',
          filterable: false,
          width: '60px'
        },
        {
          field: 'file',
          label: 'Файл',
          width: '100px',
          items: [
            {
              id: 'exist',
              title: 'Приложен'
            },
            {
              id: 'not_exist',
              title: 'Не приложен'
            },
            {
              id: 'exist_pdf',
              title: 'Приложен PDF'
            },
            {
              id: 'not_exist_pdf',
              title: 'Не приложен PDF'
            }
          ]
        },
        {
          field: 'params',
          type: 'params',
          align: 'center',
          filterable: false,
          label: 'Наличие параметров',
          width: '175px'
        },
        {
          field: 'reason_by_operator',
          label: 'Комментарий оператора ввода',
          filterable: false,
          minWidth: '200px',
          align: 'center',
          type: 'comment'
        }
      ].filter(x => this.pki.rule === 1 || x.field !== 'result')
    }
  },
  created () {
    if (this.$route.meta?.isKP) {
      this.isKP = true
    }
    this.loading = true
    if (this.isKP) {
      getStorageKpIogvList().then((response) => {
        this.iogvs = response.data.data
      })
    } else {
      getStorageIogvList().then((response) => {
        this.iogvs = response.data.data
      })
    }
    this.pki_id = parseInt(this.$route.query.pki_id)
    this.period_id = parseInt(this.$route.query.period_id)
  },
  methods: {
    getStorageCompletness () {
      if (this.isKP) {
        getstorageKpCompletness({ pki_id: this.pki_id }).then((response) => {
          this.completeness = response.data.completeness
        })
      } else {
        getstorageCompletness({ pki_id: this.pki_id }).then((response) => {
          this.completeness = response.data.completeness
        })
      }
    },
    getPeriodEnd () {
      const periodEnd = new Date(this.periodEnd)
      return periodEnd.toLocaleString()
    },
    changeTargetValue (val) {
      this.pki.target_value = val
    },
    calcPercent (a, b) {
      if (a) {
        return Math.round(((a / b) * 100))
      }
      return 0
    },
    changeDataExist (val) {
      saveDataExistMultiple({ pki_id: this.pki_id, period_id: this.period_id, data_exist: val }).then(() => {
        this.fetchData()
      })
    },
    changeDataExistNotResult () {
      saveDataExistMultipleNotResult({ pki_id: this.pki_id, period_id: this.period_id, data_exist: false }).then(() => {
        this.fetchData()
      })
    },
    fetchData (params) {
      const defaultParams = {
        pki_id: this.pki_id,
        period_id: this.period_id,
        classification_id: this.classification_id
      }
      this.params = Object.assign(defaultParams, this.params)
      this.loading = true
      this.dialogVisibleEdit = false
      if (params) {
        Object.assign(this.params, params)
      }
      if (this.isKP) {
        getStorageKpOverallList(this.params).then((response) => {
          this.tableData = response.data.data
          this.total = response.data.meta.total
          this.value_rules = response.data.meta.value_rules
          this.pki = response.data.meta.pki
          this.rule_io = response.data.meta.rule_io
          this.file_exist = response.data.meta.file_exist
          this.periodEnd = response.data.meta.period.date_stop
        }).finally(() => {
          this.loading = false
          this.getStorageCompletness()
        })
      } else {
        getStorageOverallList(this.params).then((response) => {
          this.tableData = response.data.data
          this.total = response.data.meta.total
          this.value_rules = response.data.meta.value_rules
          this.pki = response.data.meta.pki
          this.rule_io = response.data.meta.rule_io
          this.file_exist = response.data.meta.file_exist
          this.periodEnd = response.data.meta.period.date_stop
        }).finally(() => {
          this.loading = false
          this.getStorageCompletness()
        })
      }
    },
    showModalEdit (id) {
      this.storageId = id
      this.dialogVisibleEdit = true
    },
    showModalView (id) {
      this.storageId = id
      this.dialogVisibleView = true
    }
  }
}
</script>

<style scoped>

</style>
