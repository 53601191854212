<template>
  <div>
    <div class="length-text">
      <span>Показано {{ list.length }} {{ getSpell(list.length) }} из {{ total }}</span>
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      v-if="showTable"
      :data="list"
      border
      fit
      size="mini"
      class="main-table"
      :header-cell-class-name="headerClass"
      :row-class-name="tableRowClassName"
      @header-click="handleHeaderClick"
      height="80vh">
      <el-table-column
        type="expand"
        :sort-orders="[]">
        <template slot="header">
          <div
            class="el-table__expand-icon"
            v-bind:class="{'el-table__expand-icon--expanded': expanded}"
            @click="expandRows">
            <i class="el-icon el-icon-arrow-right"/>
          </div>
        </template>
        <template slot-scope="props">
          <storage-detail v-if="rerenderStorageRow" :id="props.row.id"
                          @updated="(val) => updateRow(val, props.row)"></storage-detail>
        </template>
      </el-table-column>
      <el-table-column
        v-if="needId"
        label="#"
        align="center"
        :prop="keyField"
        label-class-name="order">
        <el-table-column
          align="center"
          :prop="keyField"
          :sort-orders="[]"
          width="80px">
          <template slot="header">
            <v-input
              v-model="query[keyField]"
              @change="fetchData(true)"/>
          </template>
          <router-link
            slot-scope="scope"
            :to="detailRoute(scope.row)"
            class="link-type">
            {{ scope.row[keyField] }}
          </router-link>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-for="column in columns"
        :key="column.field"
        :label="column.label"
        :prop="column.field"
        :align="column.align || 'center'"
        label-class-name="order">
        <el-table-column
          v-if="column.type !== 'value_rule'"
          :align="column.align || 'left'"
          :prop="column.field"
          :width="column.width"
          :min-width="column.minWidth"
          :sort-orders="[]">
          <template slot="header"
                    v-if="column.type === 'params'">
            <el-button-group>
              <el-tooltip
                class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  Установить отсутствие параметров для всех ИОГВ
                </div>
                <el-button size="mini" type="danger" icon="el-icon-close" @click="changeDataExist(false)"/>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  Установить отсутствие параметров для ИОГВ, по которым не предоставлены значения
                </div>
                <el-button size="mini" type="warning" icon="el-icon-star-off" @click="changeDataExistNotResult"/>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  Установить наличие параметров для всех ИОГВ
                </div>
                <el-button size="mini" type="success" icon="el-icon-check" @click="changeDataExist(true)"/>
              </el-tooltip>
            </el-button-group>
          </template>
          <template slot="header"
                    v-else-if="column.filterable !== false">
            <el-select
              v-if="column.items"
              v-model="query[column.field]"
              size="mini"
              clearable
              filterable
              style="width: 100%"
              :multiple="column.multiple"
              @change="fetchData(true)">
              <el-option
                v-for="(item) in column.items"
                :key="item.id"
                :label="item.title"
                :value="item.id"/>
            </el-select>
            <v-input
              v-else-if="renderFilters"
              v-model="query[column.field]"
              @change="fetchData(true)"/>
          </template>
          <template slot-scope="scope">
            <el-checkbox
              v-if="column.type === 'checkbox'"
              v-model="scope.row[column.field]"
              @change="value => $emit(`${column.field}-checked`, scope.row, value)"/>
            <el-tag
              v-else-if="column.type === 'tag'"
              :type="column.resolveType(scope.row)">
              {{ getValue(scope.row, column) }}
            </el-tag>
            <el-form
              v-else-if="column.type === 'comment'"
              :model="scope.row"
              :ref="'storage-form-' + scope.row.id">
              <el-form-item
                prop="reason_by_operator"
                :rules="{ max: 500, message: 'Превышено допустимое количество знаков (500)!', trigger: ['blur', 'change'] }">
                <template slot="label">
                </template>
                <el-input
                  style="z-index: 1"
                  size="mini"
                  type="textarea"
                  maxlength="501"
                  show-word-limit
                  :title="isInputShouldBeDisabled(scope.row) ? 'Корректировка данных возможна по нажатию на элемент интерфейса Редактирование' : ''"
                  :disabled="isInputShouldBeDisabled(scope.row)"
                  v-model="scope.row[column.field]"/>
              </el-form-item>
            </el-form>
            <el-switch
              v-else-if="column.type === 'params'"
              v-model="scope.row.data_exist"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="(val) => changeParams(val, scope.row)">
            </el-switch>
            <katex-element
              v-else-if="column.type === 'formula' && scope.row[column.field].indexOf('`') >= 0"
              :expression="getFormula(scope.row[column.field])" />
            <template v-else>
              {{ getValue(scope.row, column) }}
            </template>
          </template>
        </el-table-column>
        <template v-else>
          <template v-if="value_rules.length > 0 && rule === 1">
            <el-table-column
              v-for="item in value_rules"
              :key="item.id"
              min-width="130px"
              align="center"
              :sort-orders="[]">
              <template slot="header">
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top"
                  style="margin-left: 10px">
                  <div slot="content">
                    {{ item.description }}
                  </div>
                  <div>
                    <katex-element :expression="getFormula(item.asciiMathDesignatio)"/>
                    <tempalate v-if="item.unitOfMeasurement">
                      {{ ` (${item.unitOfMeasurement.short_name})` }}
                    </tempalate>
                    <i class="fas fa-info-circle" />
                  </div>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-form
                  :model="scope.row.value_storages.find(x => x.value_rule_id === item.id)"
                  :ref="'storage-form-' + scope.row.id">
                  <el-form-item
                    prop="value"
                    :rules="scope.row.data_exist ? getRules(item) : []">
                    <template slot="label">
                    </template>
                    <el-input v-model="scope.row.value_storages.find(x => x.value_rule_id === item.id).value"
                              :disabled="isInputShouldBeDisabled(scope.row, true)"
                              :title="isInputShouldBeDisabled(scope.row) ? 'Корректировка данных возможна по нажатию на элемент интерфейса Редактирование' : ''"
                              size="mini"
                              type="number"/>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="rule === 0">
            <el-table-column
              align="center"
              :sort-orders="[]">
              <template slot="header">
                Выбор значения
                <tempalate v-if="pki.unitOfMeasurement">
                  ({{ pki.unitOfMeasurement.short_name }})
                </tempalate>
              </template>
              <template slot-scope="scope">
                <el-form
                  :model="scope.row"
                  :ref="'storage-form-' + scope.row.id">
                  <el-form-item
                    prop="result"
                    :rules="rules.result">
                    <el-select
                      v-model="scope.row.result"
                      :disabled="isInputShouldBeDisabled(scope.row, true)"
                      :title="isInputShouldBeDisabled(scope.row) ? 'Корректировка данных возможна по нажатию на элемент интерфейса Редактирование' : ''">
                      <el-option
                        v-for="item in rule_io"
                        :key="item.id"
                        :label="item.value"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="rule === -1">
            <el-table-column
              align="center"
              :sort-orders="[]">
              <template slot="header">
                  <div>
                    Ввод значения
                    <tempalate v-if="pki.unitOfMeasurement">
                      ({{ pki.unitOfMeasurement.short_name }})
                    </tempalate>
                  </div>
              </template>
              <template slot-scope="scope">
                <el-form
                  :model="scope.row"
                  :ref="'storage-form-' + scope.row.id">
                  <el-form-item
                    prop="result"
                    :rules="rules.result">
                    <el-input
                      v-model="scope.row.result"
                      :disabled="isInputShouldBeDisabled(scope.row, true)"
                      :title="isInputShouldBeDisabled(scope.row) ? 'Корректировка данных возможна по нажатию на элемент интерфейса Редактирование' : ''"
                      type="number"/>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="Сохранить" align="center" width="86px">
            <el-button slot-scope="scope"
                       :title="isInputShouldBeDisabled(scope.row) ? 'Корректировка данных возможна по нажатию на элемент интерфейса Редактирование' : ''"
                       :disabled="isInputShouldBeDisabled(scope.row)"
                       size="mini"
                       type="text"
                       @click="saveParams(scope.row)">
              <i style="width: 1.5rem; height: 1.5rem" class="far fa-save"></i></el-button>
          </el-table-column>
        </template>
      </el-table-column>
      <el-table-column
        v-if="needActions"
        label="Действия"
        align="center"
        prop="actions"
        :sort-orders="[]">
        <el-table-column
          align="center"
          prop="actions"
          :sort-orders="[]"
          width="100px">
          <template slot="header">
          </template>
          <template slot-scope="scope">
            <a title="Просмотр" @click="$emit('storage-view', scope.row.id)">
              <i class="fas fa-eye" style="margin-right: 10px"/>
            </a>
            <a title="Редактирование" @click="$emit('storage-edit', scope.row.id)">
              <i class="fas fa-edit" style="margin-right: 10px"/>
            </a>
            <el-upload
              style="display: inline"
              :v-loading="loading"
              ref="file"
              action="/backend/file/upload"
              :headers="{Authorization: 'Bearer ' + token}"
              :show-file-list="false"
              :on-success="(val) => addFile(val, scope.row)">
              <i title="Добавить файл" class="fas fa-file-upload" slot="trigger"/>
            </el-upload>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :auto-scroll="autoScroll"
      :limit.sync="query.per_page"
      :page.sync="query.current_page"
      :total="total"
      @pagination="fetchData()"/>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import VInput from '@/components/VInput'
import StorageDetail from '@/views/storage/StorageDetail'
import AsciiMathParser from 'asciimath2tex'
import { saveDataExist, saveDataExistKp, saveStorageAddFile, saveParams, saveStorageKpAddFile } from '@/api/api'
import { mapGetters } from 'vuex'

export default {
  components: { Pagination, VInput, StorageDetail },
  props: {
    keyField: {
      type: String,
      default: 'id'
    },
    name: {
      type: String,
      required: true
    },
    rule: {
      type: Number,
      required: true
    },
    pki: {
      type: Object
    },
    columns: {
      type: Array,
      default: () => []
    },
    value_rules: {
      type: Array,
      default: () => []
    },
    rule_io: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    needActions: {
      type: Boolean,
      default: false
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    needId: {
      type: Boolean,
      default: true
    },
    renderFilters: {
      type: Boolean,
      default: true
    },
    detailRoute: {
      type: Function,
      default (params) {
        return { name: this.$route.name.replace('list', 'view'), params }
      }
    }
  },
  data () {
    return {
      loading: false,
      isKP: false,
      expanded: false,
      rerenderStorageRow: true,
      query: {
        total: 0,
        filter: undefined,
        current_page: 1,
        per_page: 50,
        sort_column: undefined,
        sort_direction: undefined
      },
      rules: {
        result: {
          required: true,
          message: 'Не заполнено обязательное поле',
          trigger: ['change']
        }
      },
      filters: [],
      labels: {},
      showTable: true,
      showFilters: false
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  watch: {
    columns: {
      handler () {
        this.rerender()
      }
    }
  },
  mounted () {
    this.fetchData()
    if (this.$route.meta?.isKP) {
      this.isKP = true
    }
  },
  methods: {
    isInputShouldBeDisabled (row, isValueInput = false) {
      if (isValueInput) {
        if (row.data_exist) {
          return row.result !== null
        }
        return true
      }
      return row.result !== null
    },
    getRules (valueRule) {
      return [
        {
          required: valueRule.required,
          message: 'Не заполнено обязательное поле',
          trigger: ['change']
        },
        {
          validator: (rule, value, callback) => {
            const min = (valueRule.min !== null) && value < valueRule.min
            if ((valueRule.max !== null) && value > valueRule.max) {
              if (min) {
                callback(new Error(`Значение должно быть между ${valueRule.min} и ${valueRule.max}`))
              } else {
                callback(new Error(`Значение должно быть меньше ${valueRule.max}`))
              }
            } else if (min) {
              callback(new Error(`Значение должно быть больше ${valueRule.min}`))
            } else {
              callback()
            }
          },
          trigger: ['change']
        }
      ]
    },
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    },
    changeDataExist (val) {
      const text = `Вы уверены, что хотите установить ${val ? 'наличие' : 'отсутствие'} параметров для всех ИОГВ?`
      this.$confirm(text, 'Внимание!', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        this.$emit('change-data-exist', val)
        this.$emit('change-graph')
      })
    },
    changeDataExistNotResult () {
      this.$confirm('Вы уверены, что хотите установить отсутствие параметров для ИОГВ, по которым не предоставлены значения?', 'Внимание!', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        this.$emit('change-data-exist-not-result')
        this.$emit('change-graph')
      })
    },
    tableRowClassName ({ row }) {
      if (row.result || !row.data_exist) {
        return 'row-success'
      }
      return 0
    },
    saveParams (row) {
      const refs = this.$refs['storage-form-' + row.id]
      Promise.all(refs.map(x => x.validate())).then((vals) => {
        if (!vals.every(x => x)) {
          return
        }
        saveParams({ ...row, storage_id: row.id }).then(response => {
          this.rerenderStorage()
          row = Object.assign(row, response.data.storageModel)
          if (row.rating === 0) {
            row.rating = ''
          }
          this.$message.success('Данные успешно обновлены!')
          if (row.unit_of_measurement === '%' && row.result > 100) {
            this.$confirm('Рекомендуется перепроверить введенные значения параметров, так как рассчитанный результат составил более 100%.', 'Внимание', {
              confirmButtonText: 'Все верно',
              cancelButtonText: 'Изменить данные'
            }).catch(() => {
              this.$emit('storage-edit', row.id)
            })
          }
          this.$emit('change-graph')
        }).catch(() => {
          // this.$message.error('Не удалось сохранить!')
        })
      })
    },
    addFile (fileId, row) {
      const form = {
        file_by_operator_id: fileId
      }
      if (row.id) {
        form.storage_id = row.id
      } else {
        form.pki_id = row.pki_id
        form.period_id = row.period_id
      }
      let promise
      if (this.isKP) {
        promise = saveStorageAddFile(form)
      } else {
        promise = saveStorageKpAddFile(form)
      }
      promise.then(() => {
        this.$message.success('Файл прикреплен!')
        this.rerenderStorage()
      }).catch(() => {
        this.$message.error('Файл не удалось прикрепить!')
      })
    },
    changeParams (val, row) {
      if (!val && row.result && row.result !== '-') {
        this.$confirm('Вы уверены, что хотите очистить введенные значения?', 'Внимание!', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.saveDataExist(row.id, val, row)
          this.$emit('change-graph')
        }).catch(() => {
          row.data_exist = !val
        })
      } else {
        this.saveDataExist(row.id, val, row)
      }
    },
    saveDataExist (storageId, dataExist, row) {
      let promise
      const data = {
        storage_id: storageId,
        data_exist: dataExist,
        reason_by_operator: row.reason_by_operator
      }
      if (this.isKP) {
        promise = saveDataExistKp(data)
      } else {
        promise = saveDataExist(data)
      }
      promise.then((response) => {
        this.rerenderStorage()
        row = Object.assign(row, response.data.storageModel)
        if (row.rating === 0) {
          row.rating = ''
        }
        row.value_storages = response.data.value_storages
        this.$emit('change-graph')
      }).catch(() => {
        row.data_exist = !dataExist
      })
    },
    expandRows () {
      if (this.expanded) {
        this.expanded = false
        for (let i = 0; i < this.query.per_page; i++) {
          this.$refs.table.toggleRowExpansion(this.list[i], false)
        }
      } else {
        this.expanded = true
        for (let i = 0; i < this.query.per_page; i++) {
          this.$refs.table.toggleRowExpansion(this.list[i], true)
        }
      }
      this.rerender()
    },
    rerender () {
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
    rerenderStorage () {
      this.rerenderStorageRow = false
      this.$nextTick(() => {
        this.rerenderStorageRow = true
      })
    },
    updateRow (data, row) {
      row.result = data.storageModel.result
      row.rating = data.storageModel.rating
      row.reason_by_operator = data.storageModel.reason_by_operator
      row.data_exist = data.storageModel.data_exist
      row.value_storages = data.valueStorageModels
      this.$emit('change-target-value', data.pkiPeriodModel.target_value)
      this.$emit('change-graph')
    },
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    getValue (row, column) {
      if (column.filter) {
        return column.filter(row[column.field], row)
      }
      return row[column.field]
    },
    handleDblClick (row) {
      this.$router.push(this.detailRoute(row))
    },
    fetchData (toFirstPage = false) {
      if (toFirstPage) {
        this.query.current_page = 1
      }
      this.query.filter = this.renderFilters
      this.$emit('fetch-data', this.query)
      this.rerender()
    },
    handleHeaderClick (col) {
      if (col.sortOrders.length) {
        if (col.property === this.query.sort_column) {
          if (this.query.sort_direction === 'asc') {
            this.query.sort_direction = 'desc'
          } else {
            this.query.sort_column = undefined
            this.query.sort_direction = undefined
          }
        } else {
          this.query.sort_column = col.property
          this.query.sort_direction = 'asc'
        }
        this.fetchData()
      }
    },
    headerClass (data) {
      if (data.column.property && data.column.property === this.query.sort_column) {
        return this.query.sort_direction
      }
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

.el-table >>> thead > tr:nth-child(2) > th > div.cell {
  padding: 0 10px;
}

.buttons > * + * {
  margin-left: 10px;
}

.el-form >>> .el-form-item__error {
  margin-top: -17px;
  z-index: 0;
}

.el-table >>> .el-table__body-wrapper {
  overflow-y: auto;
}
</style>
